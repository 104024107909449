window.saveDateToSession = saveDateToSession;

function saveDateToSession() {
    let date;
    let dateField = document.getElementById('add_route_date').value;
    // sessionStorage.setItem('date', dateField)

    if (dateField) {
        // Use the value from params and store the date in session storage 
        date = dateField;
        sessionStorage.setItem('date', date)
    } else if (sessionStorage.getItem("date")) {
        // Use the value from session storage
        date = sessionStorage.getItem("date")
    }else {
        // Get today's date in the format "YYYY-MM-DD" and store the date in session storage 
        date = new Date().toISOString().slice(0, 10)
        sessionStorage.setItem("date", date)
    }
    createroutedate()
}
/*** ajax call: Get all active medtechs from database  ***/
let medtechs;
let name_av =""
let mfiltered=[]

window.getActiveMedtechs = getActiveMedtechs;

function getActiveMedtechs(type){
    $.ajax({
        type: "get",
        url: "/administrator/active_medtechs",
        dataType: 'json',
        async: false,
        
        success:function(data){
            let sdate;
            // console.log(t)
            if(type==="create"){
                sdate = sessionStorage.getItem('date')
                mfiltered=data?.map(med => {
                    // console.log(m.availability_slots)
                    
                    if (med?.availability_slots[sdate]){
                        name_av = `${med?.name} AM(${med?.availability_slots[sdate]?.[0]}),PM(${med?.availability_slots[sdate]?.[1]})`
                        // console.log(m?.availability_slots[d])                    
                    }else{
                        name_av = `${med?.name} AM(0),PM(0)`
                    }
                    return {id:med?.id, name: med?.name, label: name_av}
                })
            } else if(type==="edit"){
                sdate = sessionStorage.getItem('edit_date')
                mfiltered=data?.map(med => {
                    // console.log(m.availability_slots)
                    
                    if (med?.availability_slots[sdate]){
                        name_av = `${med?.name} AM(${med?.availability_slots[sdate]?.[0]}),PM(${med?.availability_slots[sdate]?.[1]})`                  
                    }else {
                        name_av = `${med?.name} AM(0),PM(0)`    
                    }
                    return {id:med?.id, name: med?.name, label: name_av}
                })
            }
        // console.log(mfiltered)
        }
    })
    return mfiltered
}

window.createroutedate = createroutedate;

function createroutedate(){
    getActiveMedtechs("create")
    const select = document.getElementById("route_medtech_id");
    // Remove all existing options
    while (select.firstChild) {
    select.removeChild(select.firstChild);
    }
    // Add new options
    if(mfiltered.length!=0){
        for (let i = 0; i < mfiltered.length; i++) {
            const option = document.createElement("option");
            option.value = mfiltered[i].id;
            option.text = mfiltered[i].label;
            select.appendChild(option);
        }
    }
    // Select the first option
    select.selectedIndex = 0;
}


window.saveEditDateToSession = saveEditDateToSession;

function saveEditDateToSession() {
    let date;   
    let dateField = document.getElementById('edit_route_date').value;
    let med
    let medtechfield = document.getElementById('route_medtech_id').value;
    // sessionStorage.setItem('date', dateField)

    if (dateField) {
        // Use the value from params and store the date in session storage 
        date = dateField;
        sessionStorage.setItem('edit_date', date)
    } else if (sessionStorage.getItem("edit_date")) {
        // Use the value from session storage
        date = sessionStorage.getItem("edit_date")
    }else {
        // Get today's date in the format "YYYY-MM-DD" and store the date in session storage 
        date = new Date().toISOString().slice(0, 10)
        sessionStorage.setItem("edit_date", date)
    }

    if(medtechfield){
        med = medtechfield;
        sessionStorage.setItem('medtech_field', med)
    }
    editroutedate()
}


window.editroutedate = editroutedate;

function editroutedate(){
    getActiveMedtechs("edit")
    let select = document.getElementById("route_medtech_id");
    let med_field = sessionStorage.getItem("medtech_field")
    // Remove all existing options
    while (select.firstChild) {
    select.removeChild(select.firstChild);
    }

    // Add new options
    if(mfiltered.length!=0){
        for (let i = 0; i < mfiltered.length; i++) {
            const option = document.createElement("option");
            option.value = mfiltered[i].id;
            option.text = mfiltered[i].label;
            select.appendChild(option);
        }
    }

    // Iterate through the options to find a match
    for (var i = 0; i < select.options.length; i++) {
        if (select.options[i].value === med_field) {
            // Set the selected index to the index of the matching option
            select.selectedIndex = i;
            break;
        }
    }
}




