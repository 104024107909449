import autocomplete from "js-autocomplete";

window.initMap = function initMap(address, lat, lng) {
  const geocoder = new google.maps.Geocoder();
  const infowindow = new google.maps.InfoWindow();
  const locationButton = document.createElement("button");
  const locationInput = document.querySelector("input#location");
  const searchButton = document.getElementById("location-form-search");
  let myCoords;

  myCoords = new google.maps.LatLng(14.5995, 120.9842);
  //map initialize
  let mapOptions = {
    center: myCoords,
    zoom: 17,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
  };
  let map = new google.maps.Map(document.getElementById("map"), mapOptions);

  if (address.trim().length !== 0) {
    //after map initialization, center map to location address field
    reverseGeocodeAddress(geocoder, map, address);
  } else if (lat !== undefined && lng !== undefined) {
    //center to coordinates if it is provided
    myCoords = new google.maps.LatLng(lat, lng);
    map.setCenter(myCoords);
  }

  //map marker initialize
  let marker = new google.maps.Marker({
    position: myCoords,
    map: map,
  });

  map.addListener("center_changed", () => {
    marker.setPosition(map.getCenter());
  });

  //geocode address for every map movement
  google.maps.event.addListener(
    map,
    "dragend",
    function () {
      geocodeLatLng(geocoder, map, infowindow);
    },
    { passive: true }
  );

  //search functionality using google geocoding api
  searchButton.setAttribute("remote", true);
  searchButton.addEventListener("click", () => {
    reverseGeocodeAddress(
      geocoder,
      map,
      document.getElementById("location").value
    );
    //clear google place search result
    document.querySelector(".autocomplete-results").innerHTML = "";
    document.querySelector(".autocomplete-results").style.display = "none";
  });

  //Current location functionality
  locationButton.textContent = "Go to Current Location";
  locationButton.classList.add("custom-map-control-button");
  locationButton.setAttribute("type", "button");

  map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
  locationButton.addEventListener("click", () => {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.setCenter(pos);
          geocodeLatLng(geocoder, map, infowindow);
          map.setZoom(17);
          setMapCoordinatesToFields(map);
        },

        //geolocation service error e.g. permission blocked
        handleLocationError
      );
    } else {
      // Browser doesn't support Geolocation
      handleLocationError;
    }
  });

  let autocomplete = new google.maps.places.Autocomplete(locationInput);
  autocomplete.setComponentRestrictions({
    country: ["ph"],
  });

  google.maps.event.addListener(autocomplete, "place_changed", function () {
    if (!!searchButton) {
      searchButton.click();
    }
  });
};

function handleLocationError(err) {
  console.warn(err.message);
  createNotice(err.message);
}

function geocodeLatLng(geocoder, map, infowindow) {
  const latlng = {
    lat: map.getCenter().lat(),
    lng: map.getCenter().lng(),
  };

  geocoder.geocode({ location: latlng }).then((response) => {
    if (response.results[0]) {
      map.setZoom(17);
      document.getElementById("location").value =
        response.results[0].formatted_address;
      setMapCoordinatesToFields(map);
      $.ajax({
        type: "GET",
        url: "/area/check",
        dataType: "json",
        data: { address: document.getElementById("location").value },
      });
    } else {
      createNotice("Location Search: No results found.");
    }
  });
}

function reverseGeocodeAddress(geocoder, map, address) {
  geocoder.geocode({ address: address }).then((response) => {
    if (response.results[0]) {
      document.getElementById("location").value =
        response.results[0].formatted_address;

      const coordinates = {
        lat: response.results[0].geometry.location.lat(),
        lng: response.results[0].geometry.location.lng(),
      };
      map.setCenter(coordinates);
      setMapCoordinatesToFields(map);

      $.ajax({
        type: "GET",
        url: "/area/check",
        dataType: "json",
        data: { address: document.getElementById("location").value },
      });
    } else {
      createNotice("Location Search: No results found.");
    }
  });
}

function setMapCoordinatesToFields(map) {
  let lat_field = document.querySelectorAll("[id$=map_latitude]")[0];
  let lng_field = document.querySelectorAll("[id$=map_longitude]")[0];
  if (document.body.contains(lat_field) && document.body.contains(lng_field)) {
    lat_field.value = map.getCenter().lat();
    lng_field.value = map.getCenter().lng();
  }
}

window.reverseGeocodeAddressToObject = function reverseGeocodeAddressToObject(address) {
  const geocoder = new google.maps.Geocoder();
  const placeObject = {};
  let street, village, barangay, city, country, zipcode, lat, lng;
  street = village = barangay = city = country = zipcode = "";
  geocoder.geocode({ address: address }).then((response) => {
    if (response.results[0]) {
      let place = response.results[0];

      for (let i = 0; i < place.address_components.length; i++) {
        for (let j = 0; j < place.address_components[i].types.length; j++) {
          if (place.address_components[i].types[j] == "street_number") {
            street += place.address_components[i].long_name + " ";
          } else if (place.address_components[i].types[j] == "route") {
            street += place.address_components[i].long_name + " ";
          } else if (place.address_components[i].types[j] == "postal_code") {
            zipcode = place.address_components[i].long_name;
          } else if (place.address_components[i].types[j] == "sublocality") {
            barangay = place.address_components[i].long_name;
          } else if (place.address_components[i].types[j] == "locality") {
            city = place.address_components[i].long_name;
          }
        }
      }
      lat = place.geometry.location.lat();
      lng = place.geometry.location.lng();
      formatted_address = place.formatted_address;

      placeObject.street = street;
      placeObject.village = village;
      placeObject.barangay = barangay;
      placeObject.city = city;
      placeObject.country = country;
      placeObject.zipcode = zipcode;
      placeObject.full_address = address;
      placeObject.lat = lat;
      placeObject.lng = lng;
      placeObject.formatted_address = formatted_address;
    } else {
    }
  });

  return placeObject;
};

window.initMap2 = function initMap2(lat, lng) {
  var myCoords = new google.maps.LatLng(lat, lng);
  var mapOptions = {
    center: myCoords,
    zoom: 14,
  };
  var map = new google.maps.Map(document.getElementById("map"), mapOptions);
};

window.setCoordinatesToFields = function setCoordinatesToFields(
  barangay_param,
  city_param
) {
  const geocoder = new google.maps.Geocoder();
  let lat_field = document.querySelectorAll("[id$=latitude]")[0];
  let lng_field = document.querySelectorAll("[id$=longitude]")[0];
  let address = "";

  if (!!barangay_param.trim()) {
    address = "barangay " + barangay_param;
  }

  if (!!city_param.trim()) {
    address = !!address.trim() ? address + ", " + city_param : city_param;
  }

  geocoder.geocode({ address: address }).then((response) => {
    if (response.results[0]) {
      let lat = response.results[0].geometry.location.lat();
      let lng = response.results[0].geometry.location.lng();
      if (
        document.body.contains(lat_field) &&
        document.body.contains(lng_field)
      ) {
        lat_field.value = lat;
        lng_field.value = lng;
      }
    }
  });
};

window.getApproxCoordinates = function getApproxCoordinates(barangay="", city="", address=""){
  let placeObject = reverseGeocodeAddressToObject(address);
  if(barangay === "" || city === "" || address === ""){
    return "";
  }

  if(placeObject["city"] !== "" && ( city.toLowerCase().includes(placeObject["city"].toLowerCase()) )){
      lat = placeObject["lat"].to_f
      lng = placeObject["lng"].to_f
  }else{
      let figurable_address = barangay + ", " + city + ", Philippines"
      placeObject = reverseGeocodeAddressToObject(figurable_address)
      lat = placeObject["lat"].to_f
      lng = placeObject["lng"].to_f
    end
  }

  return result = {
    latitude: lat,
    longitude: lng
  };
}
