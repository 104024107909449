import { Controller } from "stimulus";
import 'js-autocomplete/auto-complete.css';
import autocomplete from 'js-autocomplete';

export default class extends Controller {
    static targets = ["field", "input", "selected"];

    connect() {
        const vendors = JSON.parse(this.fieldTarget.dataset.vendors)
        const searchInput = this.inputTarget;

        if (vendors && searchInput) {
            new autocomplete({
                selector: searchInput,
                minChars: 1,
                source: function(term, suggest) {
                    term = term.toLowerCase();
                    const choices = vendors;
                    const matches = [];
                    for (let i = 0; i < choices.length; i++)
                        if (~choices[i].toLowerCase().indexOf(term)) matches.push(choices[i]);
                    suggest(matches.slice(0, 5));
                },
                onSelect: function (e, term, item) {                 
                    searchInput.value = term;
                }
            });
        }
    }
}