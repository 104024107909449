import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "agent-consent-modal"
    );

    let consentStatus = window.localStorage.getItem("consentStatus");
    if (consentStatus === "pending") {
      modalController.open();
    } else {
      modalController.close();
    }
  }

  agree() {
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "agent-consent-modal"
    );

    window.agreeAndGiveConsent();
    modalController.close();
  }

  close() {
    let modalContainer = document.getElementById("agent-consent-modal");
    modalContainer.classList.add("hidden");
  }

  open() {
    let modalContainer = document.getElementById("agent-consent-modal");
    modalContainer.classList.remove("hidden");
  }

  cancel() {
    let consentStatus = window.localStorage.getItem("consentStatus");
    if (consentStatus == "pending")
      window.location.href = "/frontliner/corporate_bookings/manage_bookings";
  }
}
