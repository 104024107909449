//calculate the total number of checked boxes
window.countCheckedBoxes = function countCheckedBoxes(form) {
  checkedtotal = 0;
  form
    .querySelectorAll(`#${form.id} input[type='checkbox']:checked`)
    .forEach((checked_box) => {
      checkedtotal += 1;
    });
  return checkedtotal;
};

window.getCheckedBoxesName = function getCheckedBoxesName(form) {
  cart = [];
  form
    .querySelectorAll(`#${form.id} input[type='checkbox']:checked`)
    .forEach((checked_box) => {
      cart.push(checked_box.parentElement.querySelector("label").innerText);
    });
  return cart;
};

window.addTestsToCart = function addTestsToCart() {
  let cart = [];
  let checkboxes = document.querySelectorAll('*[name^="product"]');
  for (var i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].checked) {
      let test_id = checkboxes[i].value;
      if (test_id.includes("package_")) {
        cart["Package#" + test_id.replace("package_", "")] = parseInt(
          document.querySelector("#product_quantity_" + test_id).value
        );
      } else {
        cart["Test#" + test_id.replace("test_", "")] = parseInt(
          document.querySelector("#product_quantity_" + test_id).value
        );
      }
    }
  }
  return cart;
};

window.triggerPriceCalculator = function triggerPriceCalculator(
  cart,
  code = "",
  customer = "",
  service_area = ""
) {
  cart_stringified = JSON.stringify({ ...cart });

  if (Object.keys(cart).length > 0) {
    $.ajax({
      type: "GET",
      url: "/administrator/bookings/get_total",
      data: {
        cart: cart_stringified,
        code: code,
        customer: customer,
        service_area: service_area,
      },
      dataType: "json",
      success: function (res) {
        document.getElementById("booking-subtotal").textContent =
          res.subtotal.toFixed(2);
        document.getElementById("booking-discount").textContent =
          res.total_discount.toFixed(2);
        document.getElementById("booking-service-fee").textContent =
          res.service_fee.toFixed(2);
        document.getElementById("booking-total-price").textContent =
          res.cart_price.toFixed(2);
        document.getElementById("booking-vat").textContent = res.vat.toFixed(2);
      },
    });
  } else {
    document.getElementById("booking-subtotal").textContent = "0.00";
    document.getElementById("booking-discount").textContent = "0.00";
    document.getElementById("booking-total-price").textContent = "0.00";
  }
};

window.confirmDeleteBooking = function confirmDeleteBooking(booking) {
  let cancel_reason = prompt("Enter cancellation reason to delete booking");
  if (cancel_reason != null && cancel_reason.length > 0) {
    $.ajax({
      type: "DELETE",
      url: "/administrator/bookings/" + booking,
      data: { cancel_reason: cancel_reason },
      success: function (data) {
        location.reload();
      },
    });
  } else {
    alert("Please enter a reason to cancel booking");
  }
};

window.deleteRoute = function deleteRoute(route) {
  let decision = window.confirm("Are you sure you want to delete this route?");
  if (decision) {
    $.ajax({
      type: "DELETE",
      url: `/administrator/routes/${route}`,
      success: function () {
        alert("delete success");
      },
    }).done(
      setTimeout(() => {
        window.location.reload();
      }, 500)
    );
  }
};

// Update Medtech Job Type on change
$(document).on("change", ".select-job-type", function () {
  let newJobType = $(this).val();
  let medtechId = $(this).data("medtech-id");
  let redirectURL = $(this).data("redirect-url");
  $.ajax({
    url: "/administrator/medtechs/" + medtechId,
    type: "PATCH",
    data: { medtech: { job_type: newJobType } },
    success: function () {
      // location.replace(redirectURL);
      createNotice("Job type updated successfully!");
    },
    error: function () {
      createNotice("Failed to update job type");
    },
  });
});

$(document).on("change", ".select-routing-status", function () {
  let newRoutingStatus = $(this).val();
  let bookingId = $(this).data("booking-id");
  // let redirectURL = $(this).data('redirect-url');
  $.ajax({
    url: "/administrator/bookings/update_routing_status/" + bookingId,
    type: "PATCH",
    data: { booking: { routing_status: newRoutingStatus } },
    success: function () {
      // location.replace(redirectURL);
      createNotice("Routing status updated successfully!");
    },
    error: function () {
      createNotice("Failed to update routing status");
    },
  });
});

$(document).on(
  "change",
  ".select-routing-status-corporate-booking",
  function () {
    let newRoutingStatus = $(this).val();
    let bookingId = $(this).data("corporate-booking-id");
    // let redirectURL = $(this).data('redirect-url');
    $.ajax({
      url:
        "/administrator/corporate_bookings/update_routing_status/" + bookingId,
      type: "PATCH",
      data: { corporate_booking: { routing_status: newRoutingStatus } },
      success: function () {
        // location.replace(redirectURL);
        createNotice("Routing status updated successfully!");
      },
      error: function () {
        createNotice("Failed to update routing status");
      },
    });
  }
);

$(document).on("click", "#callout-status-mass-update-select", function (e) {
  
  // require date field for confirmed or rescheduled status
  if( $("#callout-status-mass-update-select").val() == "Confirmed" || $("#callout-status-mass-update-select").val() == "Rescheduled" ){
    $("#callout-mass-update-date").prop({"disabled": false, "hidden": false, "required": true});
    $("#callout-mass-update-time").prop({"disabled": false, "hidden": false, "required": false});
  }else{
    $("#callout-mass-update-date").prop({"disabled": true, "hidden": true, "required": false});
    $("#callout-mass-update-time").prop({"disabled": true, "hidden": true, "required": false});
  }

  //Show or hide dropdown selections for data error or invalid status
  if( $("#callout-status-mass-update-select").val() == "Data Error" ){
    $("#data-error-callout-mass-update-remarks").prop({"disabled": false, "hidden": false, "required": true});
  }else{
    $("#data-error-callout-mass-update-remarks").prop({"disabled": true, "hidden": true, "required": false});
  }

  if( $("#callout-status-mass-update-select").val() == "Invalid" ){
    $("#invalid-callout-mass-update-remarks").prop({"disabled": false, "hidden": false, "required": true});
  }else{
    $("#invalid-callout-mass-update-remarks").prop({"disabled": true, "hidden": true, "required": false});
  }

  //show remarks as text field if rescheduled
  if( $("#callout-status-mass-update-select").val() == "Rescheduled" ){
    $("#rescheduled-callout-mass-update-remarks").prop({"disabled": false, "hidden": false, "required": true});
  }else{
    $("#rescheduled-callout-mass-update-remarks").prop({"disabled": true, "hidden": true, "required": false});
  }

  //show internal remarks for possible editing if confirmed status
  if( $("#callout-status-mass-update-select").val() == "Confirmed" ){
    $("#callout-status-mass-update-other-remarks").prop({"disabled": false, "hidden": false, "required": false});
  }else{
    $("#callout-status-mass-update-other-remarks").prop({"disabled": true, "hidden": true, "required": false});
  }

});

$(document).on("click", "#availment-status-remarks-mass-update-select", function (e) {
  if( $("#availment-status-remarks-mass-update-select").val() == "Others" ){
    $("#availment-custom-remarks-mass-update").prop({"disabled": false, "hidden": false, "required": true});
  }
});

$(document).on('change', '[id*="corpbooking_checkbox_"]', function(e) {
  let url = window.location.href
  let selectedList = "";

  //remove existing selected booking param and set to a variable
  if (url.indexOf('corpbooking-selected') >= 0){
    existingSelectionValues = decodeURIComponent(url).split('corpbooking-selected=').pop().split('&')[0];
    existingSelectionParam = "corpbooking-selected="+url.split('corpbooking-selected=').pop().split('&')[0];
    url.replace(existingSelectionParam, "");
    url.replace("&&","&"); //case where removed param is in middle
    selectedList = existingSelectionValues;
    
  }

  //check each checkbox and collect to a list
  $('[id*="corpbooking_checkbox_"]').each(function(){
    let checkboxValue = this.id.replace("corpbooking_checkbox_","");
    if (this.checked) {
      if(selectedList.length === 0){
        selectedList = checkboxValue;
      }else{
        selectedList = selectedList+","+checkboxValue;
      }
    }else{
      if(selectedList.length >= 0){
        let array = selectedList.split(',');
        selectedList = array.filter(function(letter){
          return letter!==checkboxValue;
        }).toString();
      }
    }
  });

  //uniq and sort list
  let uniqueItems = [...new Set(selectedList.split(','))]
  selectedList = uniqueItems.sort().toString();

  //update url params
  const urlObj = new URL(url);
  urlObj.searchParams.set('corpbooking-selected', selectedList);
  window.history.replaceState(null, null, urlObj);

  //update page anchor links
  let newAnchorParam = {'corpbooking-selected': selectedList};
  $('.page a').each(function(){
    url = this.href;
    existingSelectionParam = "corpbooking-selected="+url.split('corpbooking-selected=').pop().split('&')[0];
    if (url.indexOf('corpbooking-selected') >= 0){
      this.href = url.replace(existingSelectionParam, "");
    }
    this.href = this.href + "&" + $.param(newAnchorParam);
    this.href = this.href.replace("&&","&");
  });
});

$(document).on("submit", "#callout-status-mass-update-form", function (e) {
  let url = window.location.href;
  let selectedBookings = "";
  $(
    "#callout-status-mass-update-form #corporate_dashboard_selected_bookings"
  ).val();
  
  if (url.indexOf('corpbooking-selected') >= 0){
    selectedBookings = decodeURIComponent(url).split('corpbooking-selected=').pop().split('&')[0];
  }

  $(
    "#callout-status-mass-update-form #corporate_dashboard_selected_bookings"
  ).val(selectedBookings);
});

$(document).on("submit", "#availment-status-mass-update-form", function (e) {
  let url = window.location.href;
  let selectedBookings = "";
  $(
    "#availment-status-mass-update-form #corporate_dashboard_selected_bookings"
  ).val();
  
  if (url.indexOf('corpbooking-selected') >= 0){
    selectedBookings = decodeURIComponent(url).split('corpbooking-selected=').pop().split('&')[0];
  }

  $(
    "#availment-status-mass-update-form #corporate_dashboard_selected_bookings"
  ).val(selectedBookings);
});

window.removeFilterGroup = function removeFilterGroup() {
  let parent = document.getElementById("filter-container");
  let filterGroupCount = document.getElementsByClassName("filter-group").length;
  if (filterGroupCount > 1) {
    parent.removeChild(parent.lastElementChild);
  }
};

window.addFilterGroup = function addFilterGroup() {
  const fragment = document.createDocumentFragment();
  const parser = new DOMParser();
  let filterGroupCount = document.getElementsByClassName("filter-group").length;
  const newNode = parser.parseFromString(
    filterGroup(filterGroupCount + 1),
    "text/html"
  );
  let parent = document.getElementById("filter-container");
  fragment.appendChild(newNode.querySelector(".filter-group"));
  parent.appendChild(fragment);
};

let filterGroup = (count) =>
  `
  <div class="filter-group side-flow">
    <div class="search-field">
      <label for="filter_type_${count}">📄 Filter ${count}:</label>
      <select name="filter_type_${count}" id="filter_type_${count}"><option value="Creation date">Creation date</option>
        <option value="Servicing date">Servicing date</option>
        <option value="Update date">Update date</option>
        <option value="Preferred date 1">Preferred date 1</option>
        <option value="Preferred date 2">Preferred date 2</option>
        <option value="Preferred date 3">Preferred date 3</option>
      </select>
    </div>
    <div class="search-field">
      <label for="filter_start_date_${count}">📅 Start Date:</label>
      <input placeholder="Start date" class="datepicker" type="date" name="filter_start_date_${count}" id="filter_start_date_${count}">
    </div>
    <div class="search-field">
      <label for="filter_end_date_${count}">📅 End Date:</label>
      <input placeholder="End date" class="datepicker" type="date" name="filter_end_date_${count}" id="filter_end_date_${count}">
    </div>
    <div class="search-field">
      <label class="required-fields" for="filter_time_start_${count}">From</label>
      <input value="00:00" class="datepicker" required="required" type="time" name="filter_time_start_${count}" id="filter_time_start_${count}">
    </div>
    <div class="search-field">
      <label class="required-fields" for="filter_time_end_${count}">To</label>
      <input value="23:59" class="datepicker" required="required" type="time" name="filter_time_end_${count}" id="filter_time_end_${count}">
    </div>
  </div>
  `;

$(document).on("click", "#add-filter-group-button", function (e) {
  e.preventDefault();
  addFilterGroup();
});
