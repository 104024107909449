import { Controller } from "stimulus";
import 'js-autocomplete/auto-complete.css';
import autocomplete from 'js-autocomplete';

export default class extends Controller {
    static targets = ["field", "input", "selected"];

    connect() {
        const tests = JSON.parse(this.fieldTarget.dataset.tests)
        const searchInput = this.inputTarget;
        const selectedTests = this.selectedTarget;
        searchInput.value = "";

        if (tests && searchInput) {
            new autocomplete({
                selector: searchInput,
                minChars: 1,
                source: function(term, suggest) {
                    term = term.toLowerCase();
                    const choices = tests;
                    const matches = [];
                    for (let i = 0; i < choices.length; i++)
                        if (~choices[i]["name"].toLowerCase().indexOf(term) || choices[i]["tags"].join().toLowerCase().includes(term)){
                            matches.push(choices[i]["name"]);
                        } 
                    suggest(matches.sort().slice(0, 5));
                },
                onSelect: function (e, term, item) {                 
                    var selectedTest = document.createElement('span');
                    selectedTest.classList.add('admin-tests');
                    selectedTest.innerHTML = `${term}` + '<i class="fas fa-window-close delete"></i>';
                    selectedTests.appendChild(selectedTest);
                    searchInput.value = "";
                }
            });
        }
    }
}