import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // let otherCitySelected = this.otherCitySelected;
    // let otherBarangaySelected = this.otherBarangaySelected;

    //easy-autocomplete
    let options = {
      url: function () {
        return "/administrator/customers/get_list";
      },

      getValue: function (element) {
        return element.email;
      },

      ajaxSettings: {
        dataType: "json",
        method: "GET",
      },
      list: {
        match: {
          enabled: true,
        },
        onChooseEvent: function () {
          let id = $("#booking_customer_email").getSelectedItemData().id;
          let first_name = $("#booking_customer_email").getSelectedItemData()
            .first_name;
          let last_name = $("#booking_customer_email").getSelectedItemData()
            .last_name;
          let address = $("#booking_customer_email").getSelectedItemData()
            .address;

          $("#booking_customer_id").val(id).trigger("change");
          $("#booking_customer_first_name").val(first_name).trigger("change");
          $("#booking_customer_last_name").val(last_name).trigger("change");
          $("#booking_exact_address").val(address).trigger("change");

          $.ajax({
            type: "GET",
            url: "/administrator/customers/customer_info_complete",
            data: {
              customer_id: document.getElementById("booking_customer_id").value,
            },
            dataType: "json",
            success: function (res) {
              if (res.complete === false) {
                createNotice(
                  "Customer data is still incomplete, redirecting to customer page"
                );
                setTimeout(function () {
                  window.location.href =
                    "/administrator/customers/" +
                    document.getElementById("booking_customer_id").value +
                    "/edit";
                }, 3000);
              }
            },
          });

          let cart = addTestsToCart();
          triggerPriceCalculator(
            cart,
            document.getElementById("booking_discount").value,
            document.getElementById("booking_customer_id").value,
            document.getElementById("booking_service_area").options[
              document.getElementById("booking_service_area").selectedIndex
            ].text
          );
        },
      },
      requestDelay: 500,
    };

    let cityOptions = {
      url: function (query) {
        $("#booking_city_or_municipality_id").val("");
        // $("#booking_location_barangay").prop("readonly",true);
        return "/location/get_list?mode=2&query=" + query.toLowerCase();
      },

      getValue: function (element) {
        return element.name;
      },

      ajaxSettings: {
        dataType: "json",
        method: "GET",
      },
      list: {
        match: {
          enabled: true,
        },
        onChooseEvent: function () {
          let id = $(
            "#booking_location_city_or_municipality"
          ).getSelectedItemData().id;
          let name = $(
            "#booking_location_city_or_municipality"
          ).getSelectedItemData().name;

          $("#booking_location_barangay").prop("readonly", false);
          // otherCitySelected();
          setCoordinatesToFields(
            $("#booking_location_barangay").val(),
            $("#booking_location_city_or_municipality").val()
          );
          $("#booking_city_or_municipality_id").val(id).trigger("change");
        },
      },
      requestDelay: 500,
    };

    let barangayOptions = {
      url: function (query) {
        $("#booking_barangay_id").val("");
        return "/location/get_list?mode=1&query=" + query.toLowerCase();
      },

      getValue: function (element) {
        return element.name;
      },

      ajaxSettings: {
        dataType: "json",
        method: "GET",
      },
      list: {
        match: {
          enabled: true,
        },
        onChooseEvent: function () {
          let id = $("#booking_location_barangay").getSelectedItemData().id;
          let name = $("#booking_location_barangay").getSelectedItemData().name;
          // otherBarangaySelected();
          setCoordinatesToFields(
            $("#booking_location_barangay").val(),
            $("#booking_location_city_or_municipality").val()
          );
          $("#booking_barangay_id").val(id).trigger("change");
        },
      },
      requestDelay: 500,
    };

    $("#booking_customer_email").easyAutocomplete(options);
    $("#booking_location_barangay").easyAutocomplete(barangayOptions);
    $("#booking_location_city_or_municipality").easyAutocomplete(cityOptions);

    //google maps
    if (
      !!document.getElementById("location-latitude") ||
      !!document.getElementById("location-longitude")
    ) {
      let location_latitude =
        document.getElementById("location-latitude").value;
      let location_longitude =
        document.getElementById("location-longitude").value;
      let location_barangay = document.getElementById(
        "booking_location_barangay"
      ).value;
      let location_city = document.getElementById(
        "booking_location_city_or_municipality"
      ).value;
      if (
        location_latitude === "" ||
        location_latitude === "0.0" ||
        location_longitude === "" ||
        location_longitude === "0.0"
      ) {
        if (!!location_barangay.trim() || !!location_barangay.trim()) {
          setCoordinatesToFields(location_barangay, location_city);
        }
      } else {
        // initMap(document.getElementById("location").value);
      }
    }

    // show or hide maxicare fields
    if (document.getElementById("booking_partner_name")) {
      this.partnerSelected();
    }

    // otherCitySelected();
    // otherBarangaySelected();
  }

  applyDiscountCode() {
    let cart = addTestsToCart();
    triggerPriceCalculator(
      cart,
      document.getElementById("booking_discount").value,
      document.getElementById("booking_customer_id").value,
      document.getElementById("booking_service_area").options[
        document.getElementById("booking_service_area").selectedIndex
      ].text
    );
  }

  applyServiceArea() {
    let cart = addTestsToCart();
    triggerPriceCalculator(
      cart,
      document.getElementById("booking_discount").value,
      document.getElementById("booking_customer_id").value,
      document.getElementById("booking_service_area").options[
        document.getElementById("booking_service_area").selectedIndex
      ].text
    );
  }

  bookingStatusSelected() {
    let booking_status = document.getElementById("booking_status").value;
    if (booking_status == "Cancelled") {
      document.getElementById("cancel_reason_container").hidden = false;
      document.getElementById("booking_cancel_reason").required = true;
    } else {
      document.getElementById("cancel_reason_container").hidden = true;
      document.getElementById("booking_cancel_reason").required = false;
    }
  }

  calloutStatusSelected() {
    let callout_status = document.getElementById(
      "booking_callout_status"
    ).value;
    if (callout_status == "Invalid") {
      document.getElementById("invalid_remarks_container").hidden = false;
      document.getElementById("booking_invalid_entry_remarks").required = true;
    } else {
      document.getElementById("invalid_remarks_container").hidden = true;
      document.getElementById("booking_invalid_entry_remarks").required = false;
    }
  }

  partnerSelected() {
    let partner_name = document.getElementById("booking_partner_name").value;
  }

  // otherCitySelected() {
  //   let city = document.getElementById(
  //     "booking_location_city_or_municipality"
  //   ).value;
  //   if (city.startsWith("Others")) {
  //     document.getElementById("city_other_container").hidden = false;
  //     document.getElementById(
  //       "booking_location_city_or_municipality_other"
  //     ).required = true;
  //     document.getElementById(
  //       "booking_location_city_or_municipality_other"
  //     ).disabled = false;
  //   } else {
  //     document.getElementById("city_other_container").hidden = true;
  //     document.getElementById(
  //       "booking_location_city_or_municipality_other"
  //     ).required = false;
  //     document.getElementById(
  //       "booking_location_city_or_municipality_other"
  //     ).value = "";
  //     document.getElementById(
  //       "booking_location_city_or_municipality_other"
  //     ).disabled = true;
  //   }
  // }

  // otherBarangaySelected() {
  //   let brgy = document.getElementById("booking_location_barangay").value;
  //   if (brgy.startsWith("Others")) {
  //     document.getElementById("barangay_other_container").hidden = false;
  //     document.getElementById(
  //       "booking_location_barangay_other"
  //     ).required = true;
  //     document.getElementById(
  //       "booking_location_barangay_other"
  //     ).disabled = false;
  //   } else {
  //     document.getElementById("barangay_other_container").hidden = true;
  //     document.getElementById(
  //       "booking_location_barangay_other"
  //     ).required = false;
  //     document.getElementById("booking_location_barangay_other").value = "";
  //     document.getElementById(
  //       "booking_location_barangay_other"
  //     ).disabled = true;
  //   }
  // }

  emptyCityField(){
    console.log('here');
      $("#corporate_booking_city_id").val('');
      $("#corporate_booking_city").val('');
  }

  emptyBarangayField(){
      $("#corporate_booking_barangay_id").val('');
      $("#corporate_booking_barangay").val('');
  }

  validateForm(event) {
    let barangay_id = document.getElementById("booking_barangay_id").value;
    let city_or_municipality_id = document.getElementById(
      "booking_city_or_municipality_id"
    ).value;

    if (barangay_id == "" || city_or_municipality_id == "") {
      event.preventDefault();
      createNotice("Please add barangay, city or municipality.");
    }

    if (city_or_municipality_id == "99999") {
      document.getElementById(
        "booking_location_city_or_municipality"
      ).disabled = true;
    }

    if (barangay_id == "99999") {
      document.getElementById("booking_location_barangay").disabled = true;
    }
  }
}
