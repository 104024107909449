import { Controller } from "stimulus";

export default class extends Controller {
    static values = { pop: Boolean };

    open(modalContainer = document.getElementsByClassName("modal-container")[0]) {
        let body = document.body;
        let page = document.getElementsByClassName("page")[0];
        if (document.getElementsByClassName("page").length == 0) page = document.getElementsByClassName("foreground")[0];


        modalContainer.setAttribute("style", "display: block; z-index: 1000;");
        modalContainer.setAttribute("onclick", "closeModal()");
        this.element.setAttribute("style", "display: block;");

        if (document.getElementsByClassName("modal-backdrop").length == 0) {
            body.style.overflowY = "hidden";
            page.innerHTML += '<div class="modal-backdrop fade"></div>';
        }
    }

    close() {
        let body = document.body;

        for (const modalContainer of document.getElementsByClassName("modal-container")) {
            this.element.removeAttribute("style");
            modalContainer.setAttribute("style", "display: none; z-index: -1;");
            modalContainer.removeAttribute("onclick");
            body.style.overflowY = "unset";
            
        }

        for (const modalBackdrop of document.getElementsByClassName("modal-backdrop")) {
            modalBackdrop.remove(); 
        }
      
        window.location.href = "/administrator/routing_calendar";
        setTimeout(window.location.replace(window.location.href), 0)
    }
}