// helpers/notices.js
function checkNotices() {
    for (i = 0; i < document.getElementsByClassName('notice-float').length; i++) {
        if (document.getElementsByClassName('notice-float')[i].style.display != 'none') {
            hideNotice(i);
        }
    }
}

function hideNotice(i) {
    setTimeout(function() {
        if (!!document.getElementsByClassName('notice-float')[i]) {
            document.getElementsByClassName('notice-float')[i].style.display = 'none';
        }
    }, 2400);
}

checkNotices();
setInterval(function() {
    checkNotices();
}, 2400);

window.createNotice = createNotice;

function createNotice(message, position = "top") {
    let notice = document.createElement("p");
    let messageContent = document.createTextNode(message);
    notice.classList.add("notice-float");
    notice.classList.add(position)
    notice.appendChild(messageContent);
    document.body.appendChild(notice);
}

window.createNoticeClickable = createNoticeClickable;

function createNoticeClickable(message, position = "top") {
    let notice = document.createElement("p");
    let messageContent = document.createTextNode(message);
    let close = document.createElement("a");
    let noticeId = Date.now();
    notice.setAttribute("id", "notice-"+noticeId);

    close.onclick = function () {
        closeNotice(noticeId);
        return false; //to prevent following the link
    };

    close.appendChild(document.createTextNode("x"));
    notice.classList.add("notice-float-clickable");
    notice.classList.add(position);
    notice.appendChild(messageContent);
    notice.appendChild(close);
    document.body.appendChild(notice);
}

window.closeNotice = closeNotice;

function closeNotice(id) {
    document.getElementById('notice-'+id).style.display = 'none';
}