import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["date", "time"];
  static values = {
    timeTotal: Number,
    url: String,
  };

  connect() {
    fetch(`${this.urlValue}`)
      .then((res) => res.json())
      .then((data) => {
        this.availableDates = data.dates;
        this.availabilityHash = data.availability;
        this.availabilitySlotHash = data.availability_slot;
        this.timeBlocks = Math.ceil(this.timeTotalValue / 30.0);
        this.timeTotalHrs = (this.timeBlocks * 30) / 60.0;
        this.dates = this.availableDates.map((date) => Date.parse(date));
        window.createCalendar(
          this.availableDates.map((date) => new Date(date+ "T00:00:00+08:00")),
          new Date(this.dateTarget.value)
        ); //instantiated a date with iso 8601 date format with timezone
        this.populate();
      });
  }

  populate() {
    this.emptyContainer();
    this.createTimeBlocks(this.dateTarget.value);
  }

  createTimeBlocks(date) {
    let [schedule, blocks] = this.getSchedule(date);
    const sortedBlocks = Object.keys(blocks)
      .map((e) => (e = parseFloat(e)))
      .sort((a, b) => a - b);

    // Set defaults for start time and medtechs
    $("#scheduling_form_start_time").val(sortedBlocks[0]);
    $("#scheduling_form_medtechs").val(blocks[sortedBlocks[0]]);
    if (this.getTotalSlotAM(date)==0 && this.getTotalSlotPM(date)==0) {
      let text = document.createElement("p");
      text.classList.add("no-timeslots");
      text.innerHTML = `No available timeslots for ${new Date(
        date + "T00:00:00+08:00").toDateString()}.`; //instantiated a date with iso 8601 date format with timezone
      $("#time-blocks-container").append(text);
    } else {
      let timeBlocks = document.createElement("div");
      timeBlocks.id = "time-blocks";
      $("#time-blocks-container").append(timeBlocks);
    }

    // Create time blocks
    let renderedBlocks = [];
    sortedBlocks.forEach((element, index) => {
      let radioButton = document.createElement("button");
      if (index == 0) {
        radioButton.classList.toggle("selected");
      }
      let ID = `radio${element}`;
      blocks[element].forEach((item) => {
        ID += `M${item}`;
      });
      let blockLabel = parseFloat(element) >= 12.0 ? 'Afternoon ('+this.getTotalSlotPM(date)+' slots)': 'Morning ('+this.getTotalSlotAM(date)+' slots)';
      if (!renderedBlocks.includes(blockLabel)) {
        radioButton.innerHTML = blockLabel;
        radioButton.id = ID;
        radioButton.classList.add("time-block");
        $("#time-blocks").append(radioButton);
        renderedBlocks.push(blockLabel);
      }
    });
    
    const changeTime = this.changeTime;
    $(".time-block").click((e) => {
      changeTime(e);
    });
  }

  getTotalSlotAM(date){
    let total = 0;
    this.availabilityHash.forEach((element) => {
      if(element.availability_slot[date]){
        total += element.availability_slot[date][0];
      }
    });
    return total;
  }

  getTotalSlotPM(date){
    let total = 0;
    this.availabilityHash.forEach((element) => {
      if(element.availability_slot[date]){
        total += element.availability_slot[date][1];
      }
    });
    return total;
  }

  getSchedule(date) {
    let schedule = {};
    schedule = this.availabilityHash.map((element) => {
      let id = element.id;
      let schedule = [];

      if (element.availability_slot[date]) {
        element.availability_slot[date].forEach((i) => schedule.push(i));
      }

      let obj = { id: id, schedule: schedule };
      return obj;
    });

    // Separate schedule into chunks
    schedule?.forEach((element) => {
      let chunks = [];
      while (element.schedule?.length > 0) {
        let chunk = element.schedule?.splice(0, 2);
        chunks.push(chunk);
      }
      element.schedule = chunks;
    });

    // Get blocks to display
    let blocks = {};
    schedule?.forEach((e) => {
      e.schedule?.forEach((item) => {
        if (this.getTotalSlotAM(date)>0){
          if (blocks[6.5]) {
            blocks[6.5].push(e.id);
          } else {
            blocks[6.5] = [e.id];
          }
        }
        
        if (this.getTotalSlotPM(date)>0){
          if (blocks[12]) {
            blocks[12].push(e.id);
          } else {
            blocks[12] = [e.id];
          }
        }
      });
    });

    return [schedule, blocks];
  }

  emptyContainer() {
    $("#time-blocks-container").empty();
  }

  changeTime(e) {
    e.preventDefault();
    $("#time-blocks-container .time-block").removeClass("selected");
    let digits = e.target.id.split("radio").join("").split("M");
    e.target.classList.add("selected");
    const time = parseFloat(digits[0]);
    const ids = digits.splice(1, digits.length).map((e) => (e = parseInt(e)));
    $("#scheduling_form_start_time").val(time);
    $("#scheduling_form_medtechs").val(ids);
  }

  changeDate(e) {
    const date = new Date(parseInt(e.target.id.split("D")[1]));
    if (date.toString() !== "Invalid Date") {
      $("div.days div").removeClass("today");
      this.dateTarget.value = new Date(date).toLocaleDateString('en-CA');
      let new_date = new Date(date.setHours(0,0,0,0));
      new_date.setDate(new_date.getDate() + 1);   // temporary fix for issue date picked reflects as day before
      this.dateTarget.value = new_date.toISOString().slice(0,10);
      e.target.classList.add("today");
      this.populate();
      let form_date = document.getElementById('scheduling_form_date');
      form_date.dispatchEvent(new Event('change', { 'bubbles': true })); //trigger onchange event for hidden field
    }
  }
}