import { Controller } from "stimulus";
import 'js-autocomplete/auto-complete.css';
import autocomplete from 'js-autocomplete';

export default class extends Controller {
    static targets = ["field", "input"];

    connect() {
        const tests = JSON.parse(this.fieldTarget.dataset.tests)
        const searchInput = this.inputTarget;

        if (tests && searchInput) {
            new autocomplete({
                selector: searchInput,
                minChars: 1,
                source: function(term, suggest) {
                    term = term.toLowerCase();
                    const choices = tests;
                    const matches = [];
                    for (let i = 0; i < choices.length; i++)
                        if (~choices[i].toLowerCase().indexOf(term)) matches.push(choices[i]);
                    suggest(matches.slice(0, 5));
                },
                onSelect: function(e, term, item) {
                    //window.location.href = "/search?query=" + encodeURIComponent(term);
                    if(term.endsWith(" Bundle")){
                        window.location.href = "/search?query="+encodeURIComponent(term.substr(0, term.lastIndexOf(" Bundle")))+"&category=3&commit=Search";
                    }else{
                        window.location.href = "/search?query="+encodeURIComponent(term)+"&category=&commit=Search";
                    }
                }
            });
        }
    }
}