window.cartBadge = async function cartBadge() {
  const settings = {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      }
  };
  let size = await fetch("/cart/size", settings)
    .then((data) => data.json())
    .then((res) => parseInt(res.cart_size));
  $("#cart-badge").text(size);
  if (size <= 0) $("#cart-badge").hide();
  if (size > 0) $("#cart-badge").show();
};
