import { Controller } from "stimulus";
import Splide from '@splidejs/splide';

export default class extends Controller {
    static targets = ["splide"];

    connect() {
        new Splide(this.splideTarget, {
            perPage: 1,
            rewind: true
        }).mount();
    }
}