import { Controller } from "stimulus";



export default class extends Controller {

  

  connect(){
    //easy-autocomplete

    let cityOptions = {
      url: function(query) {
        let provinceId = $("#payment_form_location_province option:selected").val();
        if (provinceId === "1300000000"){ //if province is ncr
          provinceId = provinceId.slice(0,2);
        }else{
          provinceId = provinceId.slice(0,5);
        }

        return "/location/get_list?mode=2&preceding_code=" + provinceId + "&query=" + query.toLowerCase();
      },
    
      getValue: function(element) {
        return accent_fold(element.name);
      },
    
      ajaxSettings: {
        dataType: "json",
        method: "GET"
      },
      list: {
        match: {
          enabled: true
        },
        onChooseEvent: function() {
          let id = $("#payment_form_location_city_or_municipality").getSelectedItemData().id;
          setCoordinatesToFields($("#payment_form_location_barangay").val(),$("#payment_form_location_city_or_municipality").val());
          $("#payment_form_city_or_municipality_id").val(id).trigger("change");
          if($("#payment_form_city_or_municipality_id").val() !== ''){
            $("#payment_form_location_barangay").attr('disabled', false);
            $("#payment_form_location_barangay").removeClass('disable');
          }
        }
      },
      requestDelay: 500
    };

    let barangayOptions = {
      url: function(query) {
        let cityId = $("#payment_form_city_or_municipality_id").val();
        if (cityId === "1380600000"){ //if city is manila as manila contains submunicipalities
          cityId = cityId.slice(0,5);
        }else{
          cityId = cityId.slice(0,7);
        }
        return "/location/get_list?mode=1&preceding_code=" + cityId + "&query=" + query.toLowerCase();
      },
    
      getValue: function(element) {
        
        return accent_fold(element.name);
      },
    
      ajaxSettings: {
        dataType: "json",
        method: "GET"
      },
      list: {
        match: {
          enabled: true
        },
        onChooseEvent: function() {
          let id = $("#payment_form_location_barangay").getSelectedItemData().id;
          setCoordinatesToFields($("#payment_form_location_barangay").val(),$("#payment_form_location_city_or_municipality").val());
          // $("#payment_form_barangay_id").val(id).trigger("change");
        }
      },
      requestDelay: 500
    };

    let accentMap = {
      'á': 'a',
      'é': 'e',
      'í': 'i',
      'ó': 'o',
      'ú': 'u',
      'ñ': 'n',
      'Ñ': 'N'
    };
    function accent_fold(s) {
      if (!s) {
        return '';
      }
      var ret = '';
      for (var i = 0; i < s.length; i++) {
        ret += accentMap[s.charAt(i)] || s.charAt(i);
      }
      return ret;
    };

    $("#payment_form_location_barangay").easyAutocomplete(barangayOptions);
    $("#payment_form_location_city_or_municipality").easyAutocomplete(cityOptions);


  }

  validateForm(event){
    let barangay = document.getElementById("payment_form_location_barangay").value;
    let city_or_municipality_id = document.getElementById("payment_form_city_or_municipality_id").value;


    if(barangay == "" || city_or_municipality_id == ""){
      event.preventDefault();
      document.getElementById('payment-form-submit-button').disabled = false;
      createNotice("Please add barangay, city or municipality.");
    }

  }

  provinceChanged() {
    this.emptyCityField();
    this.emptyBarangayField();
    this.disableBarangayField();
  }

  cityChanged() {
    $("#payment_form_city_or_municipality_id").val('');
    this.emptyBarangayField();
    this.disableBarangayField();
  }

  cityIdChanged() {
    if($("#payment_form_city_or_municipality_id").val() !== ''){
      this.enableBarangayField();
    }
  }

  barangayChanged() {
    // $("#payment_form_barangay_id").val('');
  }

  disableBarangayField(){
    $("#payment_form_location_barangay").attr('disabled', true);
    $("#payment_form_location_barangay").addClass('disable');
  }
  enableBarangayField(){
    $("#payment_form_location_barangay").attr('disabled', false);
    $("#payment_form_location_barangay").removeClass('disable');
  }
  emptyBarangayField(){
    // $("#payment_form_barangay_id").val('');
    $("#payment_form_location_barangay").val('');
  }
  emptyCityField(){
    $("#payment_form_city_or_municipality_id").val('');
    $("#payment_form_location_city_or_municipality").val('');
  }

}