window.createCalendar = (datesToShow = [], dateAsToday = new Date()) => {
  const date = new Date(new Date().setHours(0,0,0,0));
  const mappedDatesToShow = datesToShow.map((date) => date.toDateString());
  const renderCalendar = (mappedDatesToShow) => {
    date.setDate(1);
    let tempDate = new Date(date);
    tempDate.setHours(0,0,0,0);
    const monthDays = document.querySelector(".days");

    const lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();

    const prevLastDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();

    const firstDayIndex = date.getDay();

    const lastDayIndex = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDay();

    const nextDays = 7 - lastDayIndex - 1;

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    document.querySelector(".date h1").innerHTML = `${
      months[date.getMonth()]
    } ${date.getFullYear()}`;

    let days = "";
    if (date.getMonth() === 0) {
      tempDate.setMonth(11);
      tempDate.setFullYear(date.getFullYear() - 1);
    } else {
      tempDate.setMonth(date.getMonth() - 1);
    }
    for (let x = firstDayIndex; x > 0; x--) {
      tempDate.setDate(prevLastDay - x + 1);
      if (mappedDatesToShow.includes(tempDate.toDateString())) {
        days += `<div class="prev-date" id="D${Date.parse(tempDate)}">${
          prevLastDay - x + 1
        }</div>`;
      } else {
        days += `<div class="prev-date disabled">${prevLastDay - x + 1}</div>`;
      }
    }
    tempDate.setDate(1);
    tempDate.setMonth(date.getMonth());
    tempDate.setFullYear(date.getFullYear());
    for (let i = 1; i <= lastDay; i++) {
      tempDate.setDate(i);
      if (
        i === dateAsToday.getDate() &&
        date.getMonth() === dateAsToday.getMonth() &&
        date.getFullYear() === dateAsToday.getFullYear() &&
        mappedDatesToShow.includes(tempDate.toDateString())
      ) {
        days += `<div class="today" id="D${Date.parse(tempDate)}">${i}</div>`;
      } else if (mappedDatesToShow.includes(tempDate.toDateString())) {
        days += `<div id="D${Date.parse(tempDate)}">${i}</div>`;
      } else {
        days += `<div class="disabled">${i}</div>`;
      }
    }
    tempDate.setDate(1);
    if (date.getMonth() === 11) {
      tempDate.setMonth(0);
      tempDate.setFullYear(date.getFullYear() + 1);
    } else {
      tempDate.setMonth(date.getMonth() + 1);
    }

    for (let j = 1; j <= nextDays; j++) {
      tempDate.setDate(j);
      if (mappedDatesToShow.includes(tempDate.toDateString())) {
        days += `<div class="next-date" id="D${Date.parse(
          tempDate
        )}">${j}</div>`;
      } else {
        days += `<div class="next-date disabled">${j}</div>`;
      }
    }
    monthDays.innerHTML = days;
  };

  document.querySelector(".prev").addEventListener("click", () => {
    if (date > new Date(new Date().setHours(0,0,0,0))) {
      if (date.getMonth() === 0) {
          date.setMonth(11);
          date.setFullYear(date.getFullYear() - 1);
      } else {
        date.setMonth(date.getMonth() - 1);
      }
      tempDate = new Date(date.setHours(0,0,0,0));
    }
    renderCalendar(mappedDatesToShow);
  });

  document.querySelector(".next").addEventListener("click", () => {
    if (date.getMonth() === 11) {
      date.setMonth(0);
      date.setFullYear(date.getFullYear() + 1);
    } else {
      date.setMonth(date.getMonth() + 1);
    }
    tempDate = new Date(date.setHours(0,0,0,0));
    renderCalendar(mappedDatesToShow);
  });

  renderCalendar(mappedDatesToShow);
};
