window.createPaymentMethod = createPaymentMethod;
var clientKey;
var paymentIntentId;
var paymentMethod;
var paymentIntent;
var paymentIntentStatus;
var bookingCode;

async function createPaymentMethod(client_Key, bookingCode) {
  bookingCode = bookingCode;
  sessionStorage.setItem("booking", bookingCode)
  clientKey = client_Key;
  paymentIntentId = clientKey.split('_client')[0];
  let cardNumber = document.getElementById("card_number").value;
  let cVc = document.getElementById("cVc").value;
  let month = document.getElementById("month").value;
  let year = document.getElementById("year").value;
  let full_name = document.getElementById("name").value;
  let e_mail = document.getElementById("email").value;
  let mobile = document.getElementById("phone").value;
  const options = {
  method: 'POST',
  headers: {Accept: 'application/json', 'Content-Type': 'application/json',
  Authorization: `Basic ${window.btoa(process.env.PAYMONGO_SECRET_KEY)}`
  },
  body: JSON.stringify({
    data: {
      attributes: {
        details: {card_number: cardNumber, cvc: cVc, exp_month: parseInt(month), exp_year: parseInt(year)},
        billing: {name: full_name, email: e_mail, phone: mobile},
        type: 'card'
        }
      }
    })
  };

  let response = await fetch('https://api.paymongo.com/v1/payment_methods', options);
  paymentMethod = await response.json();
    if (!response.ok) {
      //replace with more appropriate error message
      alert("Details that you have submitted are either incomplete or incorrect!");
     }
    else {
      attachPaymentIntent();
    }
}

async function attachPaymentIntent() {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Basic ${window.btoa(process.env.PAYMONGO_SECRET_KEY)}`
    },
    body: JSON.stringify({
      data: {
        attributes: {
          payment_method: paymentMethod.data.id,
          client_key: clientKey
        }
      }
    })
  };
  
  let response = await fetch(`https://api.paymongo.com/v1/payment_intents/${paymentIntentId}/attach`, options);
  paymentIntent = await response.json();
  var lostOrStolen = ["fraudulent", "processor_blocked", "lost_card", "stolen_card", "blocked", "highest_risk_level", "pickup_card", "restricted_card"];
  if (!response.ok) {
    if (lostOrStolen.includes(paymentIntent.errors[0].sub_code)) {
      alert('Sorry, we cannot process your card at the moment')
    } else {
      alert(`${paymentIntent.errors[0].detail}`);
    }
   }
  else {
    paymentIntentStatus = await paymentIntent.data.attributes.status;
    initStatusPaymentIntent();
  }
}

async function getPaymentIntent() {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Basic ${window.btoa(process.env.PAYMONGO_SECRET_KEY)}`
    }
  };
  
  let response = await fetch(`https://api.paymongo.com/v1/payment_intents/${paymentIntentId}?client_key=${clientKey}`, options)
  paymentIntent = await response.json();
  paymentIntentStatus = await paymentIntent.data.attributes.status;
  recheckStatusPaymentIntent();
}

function showIframeModal() {
  document.getElementById("auth").style.visibility="visible";
  document.getElementById("overlay").style.display = "block";
}

function initStatusPaymentIntent() {
  if (paymentIntentStatus === 'awaiting_next_action') {
    document.getElementById("auth").src = paymentIntent.data.attributes.next_action.redirect.url;
    showIframeModal()
  } else {
    recheckStatusPaymentIntent();
  }
}

function recheckStatusPaymentIntent() {
  if (paymentIntentStatus === 'succeeded') {
    bookingCode = sessionStorage.getItem("booking")
    window.location.replace(`/success?booking=${bookingCode}`);
  } else if(paymentIntentStatus === 'awaiting_payment_method') {
    alert(`${paymentIntent.data.attributes.last_payment_error.failed_message}`);
  } else if (paymentIntentStatus === 'processing') {
    setTimeout(getPaymentIntent(), 1000);
  }
}

window.addEventListener(
  'message',
  ev => {
    if (ev.data === '3DS-authentication-complete') {
      getPaymentIntent();
    }
  }, false
);