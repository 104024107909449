import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  openModal() {
    let aboutSoftware = document.getElementById('about-software-modal');
    aboutSoftware.classList.remove("hidden");
  }
  
  closeModal() {
    let aboutSoftware = document.getElementById('about-software-modal');
    aboutSoftware.classList.add("hidden");
  }
}