window.cartToggle = cartToggle;

function cartToggle() {
    let cart = document.querySelector("div#cart");
    let cartBadge = document.querySelector("p#cart-badge");
    let cartIcon = document.querySelector("i#cart-btn-icon");
    let bottomNav = document.querySelector("div#bottom-nav");
    if (cart != null) {
        cart.classList.toggle("hidden");
        cart.classList.toggle("pop-in");
        cart.classList.toggle("pop-out");
        cartBadge.classList.toggle("hidden");
        cartIcon.classList.toggle("fa-shopping-bag");
        cartIcon.classList.toggle("fa-times");
        bottomNav.classList.toggle("hidden");
        bottomNav.classList.toggle("fade-in");
        bottomNav.classList.toggle("faded-out");
    }
};