window.openLocationModal = openLocationModal;

function openLocationModal() {
    let toggle = document.getElementById('location-modal-trigger');

    if (toggle != null) {
        toggle.click();
    }
}

window.openDiscountsModal = openDiscountsModal;

function openDiscountsModal() {
    let toggle = document.getElementById('discounts-modal-trigger');

    if (toggle != null) {
        toggle.click();
    }
}

window.openAnchorsModal = openAnchorsModal;

function openAnchorsModal() {
    let toggle = document.getElementById('anchors-modal-trigger');
    if (toggle != null) {
        toggle.click();
    }
}

window.openRoutesModal = openRoutesModal;

function openRoutesModal(editeddate,routes) {
    let toggle = document.getElementById('routes-list-modal-trigger');

    if (toggle != null) {
     
        toggle.click();
    }
    // console.log("open routes modal")
}

window.openRoutingModal = openRoutingModal;

function openRoutingModal(bookingId, medtechId, timeStart, timeEnd) {
    let toggle = document.getElementById('routing-modal-trigger');
    
    let bookingIdInput = document.getElementById('routing_booking_id');
    let medtechIdInput = document.getElementById('routing_medtech_id');
    let timeStartInput = document.getElementById('routing_time_start');
    let timeEndInput = document.getElementById('routing_time_end');
  
    if (toggle != null) {
        bookingIdInput.value = bookingId;
        const medtechs = Array.from(medtechIdInput.options);
        medtechs.forEach((option, i) => {
            if (option.value === medtechId) {
                medtechIdInput.selectedIndex = i;
                option.setAttribute('selected', 'selected')
            } else {
                medtechIdInput.selectedIndex = i;
                option.removeAttribute('selected')
            }
        });
        timeStartInput.setAttribute('value', timeStart);
        timeEndInput.setAttribute('value', timeEnd);

        toggle.click();
    }
}

// ROUTING CALENDAR -> CREATE ROUTE MODAL
window.openCreateRouteModal = openCreateRouteModal;

function openCreateRouteModal() {

    let toggle = document.getElementById('route-modal-trigger');

    if (toggle != null) {
        toggle.click();
    }
}

// UPDATE ROUTING CALENDAR INTERNAL EVENTS (NEW VERSION USING BOOKING MODEL)
window.openCalendarEventsModal = openCalendarEventsModal;

let olddate,newdate,rid;

function openCalendarEventsModal(bookingId, bcode, bclass, bstatus, cstatus, sstatus, bis_ncr, customerName, timeStart, timeEnd, bookingDate, bookingMedtech, location, remarks, cart, cal_route, cal_route_name) {
    let toggle = document.getElementById('calendar-events-modal-trigger');
     
    let bookingIdInput = document.getElementById('cal_booking_id');
    let bCode = document.getElementById('cal_code');
    let bookingClass = document.getElementById('cal_event_class');
    let bookingCode =  document.getElementById('cal_booking_code');
    let customerNameInput = document.getElementById('cal_customer_name');
    let timeStartInput = document.getElementById('cal_time_start');
    let timeEndInput = document.getElementById('cal_time_end');
    let dateInput = document.getElementById('cal_date');
    let medtechIdInput = document.getElementById('cal_medtech_id');
    let locationInput = document.getElementById('cal_location');
    let remarksInput = document.getElementById('cal_remarks');
    let cartInput = document.getElementById('cal_cart');
    let isNcr = document.getElementById('cal_is_ncr');
    let redirect_btn = document.getElementById('redirect-btn');
    let bookingDiv = document.getElementById('ev-booking');
    let corpBookingDiv = document.getElementById('ev-corporate-booking');
    let ncrDiv = document.getElementById('ev-is-ncr');
    let bStatusInput = document.getElementById('routing_status');
    let cStatusInput = document.getElementById('routing_callout_status');
    let sStatusInput = document.getElementById('routing_specimen_status');
    let calroute = document.getElementById('cal_route_id');
    let calrouteName = document.getElementById('cal_route_name');
    let url, cartList, test, quantity;
    
    rid = cal_route
    // console.log(bookingDiv)
    if (toggle != null) {
        if(bclass=="Booking") {
            cartList = cart?.map( b => {
            if(b){
                test = b.test
                quantity = b.quantity 

                return `${test} [${quantity}]`
            }
            }).join(', ')
        } else {
            cartList = cart
        }

        bookingClass.innerText = bclass;
        bookingCode.innerText = bcode;
        url = bclass === "Booking" ? `bookings/${bcode}/edit` : `corporate_bookings/${bcode}/edit`

        const statt = Array.from(bStatusInput.options)
        statt.forEach((option, i) => {
            if (option.value == bstatus) {
                bStatusInput.selectedIndex = i;
                option.setAttribute('selected', 'selected')
            } else {
                bStatusInput.selectedIndex = i;
                option.removeAttribute('selected')
            }
        });

        const cstattt = Array.from(cStatusInput.options);
        cstattt.forEach((option, i) => {
            if (option.value == cstatus) {
                cStatusInput.selectedIndex = i;
                option.setAttribute('selected', 'selected')
                // console.log(cStatusInput)
            } else {
                cStatusInput.selectedIndex = i;
                option.removeAttribute('selected')
            }
        })

        const sstat = Array.from(sStatusInput.options);
        sstat.forEach((option, i) => {
            if (option.value == sstatus) {
                sStatusInput.selectedIndex = i;
                option.setAttribute('selected', 'selected')
                // console.log(sStatusInput)
            } else {
                sStatusInput.selectedIndex = i;
                option.removeAttribute('selected')
            }
        })
        medtechIdInput.setAttribute('value', bookingMedtech)
        bookingIdInput.setAttribute('value', bookingId)
        bCode.setAttribute('value', bcode)
        customerNameInput.setAttribute('value', customerName)
        timeStartInput.setAttribute('value', timeStart) 
        timeEndInput.setAttribute('value', timeEnd)
        dateInput.setAttribute('value', bookingDate)
        locationInput.setAttribute('value', location)
        remarksInput.setAttribute('value', remarks)
        calrouteName.setAttribute('value', cal_route_name)
        cartInput.innerHTML= cartList
        olddate=bookingDate
        redirect_btn.setAttribute('onclick',`location.href="${url}"`) 
        toggle.click()
    }
}

// Update calendar events on button click
function updateCalendarEvents(){
    let bookingIdInput = document.getElementById('cal_booking_id').value
    let bookingCodeInput = document.getElementById('cal_booking_code').value
    let timeStartInput = document.getElementById('cal_time_start').value
    let timeEndInput = document.getElementById('cal_time_end').value
    let dateInput = document.getElementById('cal_date').value
    // let medtechIdInput = document.getElementById('cal_medtech_id').value
    let remarksInput = document.getElementById('cal_remarks').value
    let cStatusInput = document.getElementById('routing_callout_status');
    let sStatusInput = document.getElementById('routing_specimen_status');
    newdate = dateInput
    let data = {
        date: dateInput,
        time_start: timeStartInput,
        time_end: timeEndInput,
        // medtech_id: medtechIdInput,
        remarks: remarksInput
    }
    // console.log(bookingIdInput)
    fetch(`/administrator/routing/edit/booking_events/${bookingCodeInput}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((data) => {
        console.log('Success');
    })
    .catch((error) => {
        console.error('Error:', error);
    });
}

function editdate(){
    let caltitle =  document.querySelector(".ec-title").innerText;
    let yr;
    let res;
    let check = caltitle.charAt(1)
    if(caltitle.length === 9 && check === '/' ){
      yr = caltitle.slice(5, 9)
      res = caltitle.replace("/", "-")
      editeddate = yr + "-0" + res.slice(0,4)
    } else if(caltitle.length === 9){
      yr = caltitle.slice(5, 9)
      res = caltitle.replace("/", "-")
      editeddate = yr + "-" + res.slice(0,3) + "0" + res.slice(3,4)
    }
    else if(caltitle.length === 8){
      yr = caltitle.slice(4, 8)
      res = caltitle.replace("/", "-")
      editeddate = yr + "-0" + res.slice(0,2) + "0" + res.slice(2,3)
    }
    else{
      yr = caltitle.slice(6, 10)
      res = caltitle.replace("/", "-")
      editeddate = yr + "-" + res.slice(0,5)
    }
    // console.log(editeddate)
    return editeddate
}

window.closeModal = closeModal;

function closeModal() {
    for (const toggle of document.getElementsByClassName('modal-dismiss-btn')) {
        
        if (toggle != null) {
            toggle.click();
        }
    }
    // setdates() 
}